import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFileData } from '@services/file-transfer';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AbstractControl, FormControl } from '@angular/forms';
import { UploadFileResp } from '@interfaces';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {
  @Input() fControl: FormControl | AbstractControl;
  @Input() uploadReq: (file: IFileData) => Observable<UploadFileResp>;

  acts = {
    isLoading: false,
  };

  tempData = {
    image: null,
  };

  @Output() emitUploadEnd = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  onGetImageData(fileData: IFileData) {
    this.tempData.image = fileData.file?.url;
    this.acts.isLoading = true;
    this.uploadReq(fileData)
      .pipe(finalize(() => (this.acts.isLoading = false)))
      .subscribe((res) => this.fControl.setValue(res.location));
  }

  removeMedia() {
    this.tempData.image = null;
    this.fControl.setValue(null);
  }

  get uploadBtnTitle() {
    if (this.acts.isLoading) return 'uploading...';
    return this.fControl.value ? 'replace' : 'upload';
  }
}
