import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { tap } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

export interface IAutoCompleteScrollEvent {
  autoComplete: MatAutocomplete;
  scrollEvent: Event;
}

/* tslint:disable */
@Directive({
  selector: 'mat-autocomplete[scrolled]'
})
/* tslint:enable */
export class MatAutocompleteOptionsScrollDirective implements OnInit, OnDestroy {
  // the bottom percentage point of the scroll nob relatively to the infinite-scroll container
  @Input() useInfinityScroll = true;
  @Input() scrollDistance = 2;

  // this will callback if the distance threshold has been reached on a scroll down.
  @Output() scrolled = new EventEmitter<IAutoCompleteScrollEvent>();

  constructor(public autoComplete: MatAutocomplete) {}

  ngOnInit() {
    if (this.useInfinityScroll) {
      this.autoComplete.opened.pipe(
          tap(() => {
            // Note: When autocomplete raises opened, panel is not yet created (by Overlay)
            // Note: The panel will be available on next tick
            // Note: The panel wil NOT open if there are no options to display
            setTimeout(() => {
              // to prevent double listeners
              if (this.autoComplete.panel) this.autoComplete.panel.nativeElement.onscroll = this.onScroll.bind(this);
            });
          }),
          untilDestroyed(this)
      ).subscribe();

      this.autoComplete.closed.pipe(
          tap(() => this.removeScrollEventListener()),
          untilDestroyed(this)
      ).subscribe();
    }
  }

  onScroll(event: Event) {
    const scrollEvent: IAutoCompleteScrollEvent = {
      autoComplete: this.autoComplete,
      scrollEvent: event
    };

    if (this.scrollDistance === undefined) {
      this.scrolled.emit(scrollEvent);
    } else {
      const { scrollHeight, scrollTop, clientHeight } = event.target as any;

      const threshold = (100 - this.scrollDistance * 10) * scrollHeight / 100;
      const current = scrollTop + clientHeight;

      if (current > threshold) {
        this.scrolled.emit(scrollEvent);
      }
    }
  }

  private removeScrollEventListener() {
    this.autoComplete.panel.nativeElement
        .removeEventListener('scroll', this.onScroll);
  }

  ngOnDestroy() {}
}
