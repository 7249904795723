import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, distinctUntilChanged, finalize, map } from 'rxjs/operators';

import { MatOption, PaginatedData, QueryParams } from '@interfaces';
import { QUERY_MIN_LENGTH } from '@const';
import { Observable } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-universal-autocomplete',
  templateUrl: './universal-autocomplete.component.html',
  styleUrls: ['./universal-autocomplete.component.scss'],
})
export class UniversalAutocompleteComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) autoInput: MatAutocompleteTrigger;

  @Input() fControl: FormControl | AbstractControl;
  @Input() searchMethod: (q: QueryParams) => Observable<PaginatedData<any>>;

  // params
  @Input() queryParams = new QueryParams();
  @Input() queryMinLength = QUERY_MIN_LENGTH;
  @Input() useInfinityScroll = false;

  optionsArr: MatOption[] = [];

  acts = {
    isSearching: false,
    isNoMoreData: false,
  };

  // style
  @Input() placeholder: string;
  @Input() label: string;
  // @Input() disabled: string; // use by form control .disable()
  @Input() clearBtn = false;
  @Input() required = false;
  @Input() wrapperCssClass: string;
  @Input() class: string; // will give effect like <app-universal-input class="input-container short">
  @Input() attrAutocomplete: string; // chrome autocomplete attribute

  @Output() emitSelect = new EventEmitter<MatOption>();

  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.fControl.valueChanges
      .pipe(untilDestroyed(this), debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        if (typeof value === 'object') {
          return this.emitSelect.emit(value);
        }
        if (!value.length || value.length >= this.queryMinLength) {
          this.initSearch(new QueryParams(value));
        }
      });
  }

  initSearch(queryParams: QueryParams, shouldConcat?: boolean) {
    this.acts.isSearching = true;
    this.queryParams = queryParams;

    // don't forget to apply searchKeys
    this.searchMethod(queryParams)
      .pipe(finalize(() => (this.acts.isSearching = false)))
      .subscribe((res) => {
        const data: MatOption[] = res.data.map((it) => ({ value: it.id, viewValue: it.title }));
        this.optionsArr = shouldConcat ? this.optionsArr.concat(data) : data;
        this.acts.isNoMoreData = res.totalPages <= res.currPage;
      });
  }

  onClickInput() {
    this.autoInput.openPanel();
    if (!this.optionsArr.length && !this.queryParams.query) {
      this.initSearch(this.queryParams);
    }
  }

  onClear() {
    this.fControl.setValue('');
    this.autoInput.openPanel();
  }

  displayOption(option) {
    return option ? option.viewValue : undefined;
  }

  onScrolled() {
    if (this.useInfinityScroll && !this.acts.isSearching && !this.acts.isNoMoreData) {
      this.queryParams.page++;
      this.initSearch(this.queryParams, true);
    }
  }

  ngOnDestroy() {}
}
