import { Component, Input, OnInit } from '@angular/core';
import { TagsRoute } from '@const';
import { Tag } from '../..';

@Component({
  selector: 'app-tags-chips',
  templateUrl: './tags-chips.component.html',
  styleUrls: ['./tags-chips.component.scss']
})
export class TagsChipsComponent implements OnInit {
  @Input() tags: (Tag )[];

  TagsRoute = TagsRoute;

  constructor() { }

  ngOnInit(): void {}

}
