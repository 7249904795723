export class CommonSchema {
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

export class CommonItemSchema extends CommonSchema {
  isPublic: boolean;
}

