import { NgModule } from '@angular/core';
import { MomentPipe } from './moment/moment.pipe';
import { StringNormalizerPipe } from './string-normalizer/string-normalizer.pipe';
import { SanitizerPipe } from './sanitizer/sanitizer.pipe';
import { GetErrorMessagePipe } from './error-message/error-message.pipe';
import { LineBreakPipe } from './line-break/line-break.pipe';
import { HumanizePipe } from './humanize/humanize.pipe';
import { OptionalPipe } from './optional/optional.pipe';

@NgModule({
  imports: [],
  declarations: [
    MomentPipe,
    StringNormalizerPipe,
    SanitizerPipe,
    GetErrorMessagePipe,
    LineBreakPipe,
    HumanizePipe,
    OptionalPipe,
  ],
  exports: [
    MomentPipe,
    StringNormalizerPipe,
    SanitizerPipe,
    GetErrorMessagePipe,
    LineBreakPipe,
    HumanizePipe,
    OptionalPipe,
  ]
})
export class PipesModule {}
