<div class="preview-file-wrapper">
  <div class="card" *ngIf="fControl.value">
    <p>{{ fControl.value }}</p>

    <app-universal-button
      size="small"
      icon="close"
      class="close-btn"
      tooltip="remove image"
      (click)="removeMedia()"
      *ngIf="!acts.isLoading"
    ></app-universal-button>

    <app-waiting *ngIf="acts.isLoading"></app-waiting>
  </div>

  <app-universal-button
    btnType="file"
    [btnTitle]="uploadBtnTitle"
    [icon]="acts.isLoading ? 'autorenew' : 'attach_file'"
    (emitFileData)="onGetFileData($event)"
    class="upload-btn"
    [ngClass]="{'loading': acts.isLoading}"
    [disabled]="acts.isLoading"
    [acceptFileType]="'application/pdf'"
  ></app-universal-button>
</div>
