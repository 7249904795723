import { Pipe, PipeTransform } from '@angular/core';

type DataType = 'publicStatus';

@Pipe({
  name: 'humanize'
})
export class HumanizePipe implements PipeTransform {

  transform(val: string | boolean, dataType: DataType = 'publicStatus'): string {
    switch (dataType) {
      case 'publicStatus':
      default:
        return val ? 'public' : 'hidden';
    }
  }

}
