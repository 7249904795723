<div class="preview-img-wrapper">
  <div class="card" *ngIf="fControl.value">
    <img [src]="fControl.value || tempData.image | sanitizer">

    <app-universal-button
        size="small"
        icon="close"
        class="close-btn"
        tooltip="remove image"
        (click)="removeMedia()"
        *ngIf="!acts.isLoading"
    ></app-universal-button>

    <app-waiting *ngIf="acts.isLoading"></app-waiting>
  </div>

  <app-universal-button
      btnType="file"
      [btnTitle]="uploadBtnTitle"
      [icon]="acts.isLoading ? 'autorenew' : 'insert_photo'"
      (emitFileData)="onGetImageData($event)"
      class="upload-btn"
      [ngClass]="{'loading': acts.isLoading}"
      [disabled]="acts.isLoading"
  ></app-universal-button>
</div>
