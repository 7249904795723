import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CommonSchema, MatOption, QueryParams } from '@interfaces';
import { Moment } from 'moment';

interface Obj extends CommonSchema {
  [key: string]: any;
}

export interface FilterCmpOption<T> extends MatOption {
  value: keyof T & string;
  type: 'string' | 'date' | 'select';
  optionsArr?: MatOption[];
  validators?: ValidatorFn[];
}

export type FilterCmpSearchKey<T> = keyof T | MatOption<keyof T>;

const commonFilters: FilterCmpOption<CommonSchema>[] = [
  {
    value: 'id',
    viewValue: 'object ID',
    type: 'string',
    validators: [Validators.pattern(/^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i)]
  },
  {
    value: 'updatedAt',
    viewValue: 'updated at',
    type: 'date',
  },
];

interface FiltersForm {
  [key: string]: { from: Moment | undefined; to: Moment | undefined } & {equal: MatOption & string | undefined};
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Input() searchKeys: FilterCmpSearchKey<Obj>[] = [];
  @Input() filters: FilterCmpOption<Obj>[] = [];
  @Input() searchHidden = false;

  searchKeysOpts: MatOption<keyof Obj>[];
  initialFilters: FilterCmpOption<Obj>[] = [];
  availableFilters: FilterCmpOption<Obj>[] = [];
  selectedFilters: FilterCmpOption<Obj>[] = [];

  form = this.fb.group({
    query: ['', [Validators.minLength(2)]],
    searchKey: [null],
    filters: this.fb.group({}),
  });

  @Output() emitRequest = new EventEmitter<QueryParams>();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    // Prepare filters
    this.initialFilters = [...this.filters, ...commonFilters];
    this.fetchAvailableFilters();

    // Normalize search keys and set def value
    this.searchKeysOpts = this.searchKeys.map((key) => {
      if (typeof key === 'object') return key;
      if (typeof key === 'string') return { value: key.toLowerCase(), viewValue: key };
    });
    const searchKeyCtrl = this.form.get('searchKey');
    searchKeyCtrl.setValue(this.searchKeysOpts[0]);
    if (this.searchKeysOpts.length < 2) searchKeyCtrl.disable();
  }

  addFilter(filter: FilterCmpOption<Obj>) {
    if (this.filtersForm.contains(filter.value)) return;

    switch (filter.type) {
      case 'date':
        const dateCtrl = this.fb.group({ from: [null], to: [null] });
        this.filtersForm.addControl(filter.value, dateCtrl);
        break;
      case 'string':
      case 'select':
        const selectCtrl = this.fb.group({ equal: [null, filter.validators] });
        this.filtersForm.addControl(filter.value, selectCtrl);
        break;
    }
    this.selectedFilters.push(filter);
    this.fetchAvailableFilters();
  }

  get filtersForm(): FormGroup {
    return this.form.get('filters') as FormGroup;
  }

  removeFilter(filter: FilterCmpOption<Obj>, index: number) {
    this.filtersForm.removeControl(filter.value);
    this.selectedFilters.splice(index, 1);
    this.fetchAvailableFilters();
    this.submit();
  }

  fetchAvailableFilters() {
    this.availableFilters = this.initialFilters.filter((f) => !this.selectedFilters.some((sf) => sf.value === f.value));
  }

  async submit() {
    if (this.form.invalid) return;
    const query = this.form.get('query').value;
    const searchKeys = [this.form.get('searchKey').value?.value];

    // prepare filters
    const filtersVal: FiltersForm = this.filtersForm.value;
    const filters: QueryParams['filters'] = {};
    for await (const f of this.selectedFilters){
      const name = f.value;
      switch (f.type) {
        case 'date':
          const { from, to } = filtersVal[name];
          filters[name] = {
            from: from?.toDate(),
            to: to?.toDate(),
          };
          break;
        case 'select':
          const { equal } = filtersVal[name];
          filters[name] = {
            equal: equal?.value,
          };
          break;
        case 'string':
          filters[name] = {
            equal: filtersVal[name].equal || undefined,
          };
          break;
      }
    }
    this.emitRequest.emit({ query, searchKeys, filters } as QueryParams);
  }
}
