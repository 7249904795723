export const AUTH_ROOT = 'auth';
export const AUTHORIZED_ROOT = '';


// SIGN IN
const signIn = 'sign-in';
export const SignInRoute = {
  ROOT: signIn,
  ROOT_PATH: `/${AUTH_ROOT}/${signIn}`
};

// SIGN UP
const signUp = 'sign-up';
export const SignUpRoute = {
  ROOT: signUp,
  ROOT_PATH: `/${AUTH_ROOT}/${signUp}`
};

const makeRootRoute = (feature: string) => ({
  ROOT: feature,
  ROOT_PATH: `/${feature}`
});

// DASHBOARD
// export const DashboardRoute = makeRootRoute('dashboard');

// PROFILE
export const ProfileRoute = makeRootRoute('');

const makeComplexRoute = (feature: string) => ({
  ROOT: feature,
  ROOT_PATH: `/${feature}`,
  DETAILS: `:id`,
  getDetailsPath: (id) => id ? `/${feature}/${id}` : null,
  ADD: `add`,
  ADD_PATH: `/${feature}/add`,
  EDIT: `:id/edit`,
  getEditPath: (id) => id ? `/${feature}/${id}/edit` : null,
});

// QUESTIONS
export const QuestionsRoute = makeComplexRoute('questions');

// TAGS
export const TagsRoute = makeComplexRoute('tags');

// RESOURCES
export const CarePlanRoute = makeComplexRoute('care-plans');

// RESOURCES
export const ResourceRoute = makeComplexRoute('resources');

// CONTENT
export const ContentRoute = makeComplexRoute('content');
