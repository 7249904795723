import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { ApiService, AuthResp } from '@services/api';
import { SessionService } from '@services/session';
import { Router } from '@angular/router';
import { SignInRoute } from '@const';

interface LoginReq {
  email: string;
  password: string;
}

interface LoginResp extends AuthResp {
  refreshToken: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router, private apiSrv: ApiService, private sessionSrv: SessionService) {}

  login(params: LoginReq, isRememberMe?: boolean) {
    return this.apiSrv
      .post('/admin/login', params)
      .pipe(tap((data: LoginResp) => this.sessionSrv.saveData(data, isRememberMe)));
  }

  logout() {
    this.router.navigate([SignInRoute.ROOT_PATH]).then(() => this.sessionSrv.clearData());
  }
}
