import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optional'
})
export class OptionalPipe implements PipeTransform {

  transform(value: unknown, stub = '-'): unknown {
    return value || stub;
  }

}
