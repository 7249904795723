<div class="card f-c-sb">
  <div class="f-c-c">
    <app-universal-button
        styleType="icon-button"
        icon="arrow_back_ios"
        class="back-btn"
        (click)="goBack()"
    ></app-universal-button>

    <h2>{{ title }}</h2>
  </div>

  <div class="ctrl-block f-c-c">
    <app-universal-button
        *ngIf="ctrlButtons.includes('edit')"
        styleType="raised-button"
        icon="edit"
        btnTitle="edit"
        color="default"
        (click)="emitEdit.emit()"
    ></app-universal-button>
    <app-universal-button
        *ngIf="ctrlButtons.includes('delete')"
        styleType="raised-button"
        icon="delete"
        btnTitle="delete"
        color="warn"
        (click)="emitDelete.emit()"
    ></app-universal-button>
    <app-universal-button
        *ngIf="ctrlButtons.includes('save')"
        styleType="raised-button"
        icon="save"
        btnTitle="save"
        (click)="emitSave.emit()"
    ></app-universal-button>
  </div>
</div>
